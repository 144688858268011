import classNames from 'classnames';
import PropTypes from 'prop-types';

import { DOG, formatCurrency, formatISODate } from '@pumpkincare/shared';
import { Body1, Body2, LegalBody, Link, Typography } from '@pumpkincare/shared/ui';

import styles from './claim-invoice.css';

function ClaimInvoice(props) {
  const { createdAt, id, pet, status, subClaims, summaryUrls } = props;

  return (
    <div data-testid='claim-invoice' className={styles.container}>
      <div className={styles.header}>
        <div className={styles.invoiceInfo}>
          <Body1 isBold>{id}</Body1>

          <div className={styles.petName}>
            <img
              src={
                pet.species === DOG
                  ? '/assets/member-center/claims/dog-icon.png'
                  : '/assets/member-center/claims/cat-icon.png'
              }
              alt={pet.species}
              width={32}
              height={32}
            />

            <LegalBody style={{ marginLeft: '8px' }}>{pet.name}</LegalBody>
          </div>

          <LegalBody>Date Submitted: {formatISODate(createdAt)}</LegalBody>
        </div>

        <Body2 className={styles.status}>{status}</Body2>
      </div>

      {subClaims.map((claim, index) => (
        <div key={claim.id}>
          <LegalBody style={{ marginBottom: '8px' }}>
            <b>{claim.id}</b>
          </LegalBody>

          <div className={styles.amounts}>
            <div className={styles.amount}>
              <LegalBody>Amount Claimed:</LegalBody>
              <LegalBody>
                {formatCurrency(claim.claimedAmount, { areCents: true })}
              </LegalBody>
            </div>

            <div className={styles.amount}>
              <LegalBody>Amount Paid:</LegalBody>
              <LegalBody>
                {status === 'Completed'
                  ? formatCurrency(claim.paidAmount, { areCents: true })
                  : ''}
              </LegalBody>
            </div>
            {summaryUrls && index + 1 === subClaims.length ? (
              <div className={styles.downloadUrl}>
                <div>
                  {summaryUrls.length > 1 ? (
                    <Link
                      href={summaryUrls[1].url}
                      className={classNames(Typography.body2, Typography.bold)}
                    >
                      {' '}
                      <img
                        className={styles.downloadIcon}
                        alt={`Download ` + summaryUrls[1].label}
                        src={`/assets/images/iconImages/download.svg`}
                      />{' '}
                      {summaryUrls[1].label}
                    </Link>
                  ) : null}
                </div>
                <div>
                  <Link
                    href={summaryUrls[0].url}
                    className={styles.downloadAlignment}
                  >
                    {' '}
                    <img
                      className={styles.downloadIcon}
                      alt={`Download ` + summaryUrls[0].label}
                      src={`/assets/images/iconImages/download.svg`}
                    />{' '}
                    <Body2 isBold className={styles.textColor}>
                      {summaryUrls[0].label}
                    </Body2>
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}

ClaimInvoice.propTypes = {
  createdAt: PropTypes.string,
  id: PropTypes.string,
  pet: PropTypes.shape({}),
  status: PropTypes.string,
  subClaims: PropTypes.array,
  summaryUrls: PropTypes.array,
};

ClaimInvoice.defaultProps = {
  createdAt: '',
  id: '',
  pet: {},
  status: '',
  subClaims: [],
  summaryUrls: [],
};

export default ClaimInvoice;
