import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QF_URL, WELLNESS_URL } from '@pumpkincare/config';
import { QUOTE_ID_COOKIE_NAME } from '@pumpkincare/quotes';
import { CAT, DOG, removeCookie, useBooleanInput } from '@pumpkincare/shared';
import {
  ButtonStyles,
  ChevronRight,
  LegalBody,
  Link,
  Spinner,
} from '@pumpkincare/shared/ui';
import {
  getPetsByStatus,
  getUserPets,
  PetCard,
  useUserAllPetPhotos,
  useUssr,
} from '@pumpkincare/user';

import MemberCenterBanners from '../member-center-banners';

import styles from './member-pets.css';

import catPlaceholder from '../shared/cat_placeholder_porkchopgray.png';
import dogPlaceholder from '../shared/dog_placeholder_porkchopgray.png';
import callout from './callout.png';

const PLACEHOLDER = { [DOG]: dogPlaceholder, [CAT]: catPlaceholder };

function MemberPets() {
  const [isAllPets, toggleAllPets] = useBooleanInput(false);
  const { punks1012SawFe } = useFlags();
  const { data: petsData, isFetching } = useUssr(getUserPets);
  const { data: userPetPhotos } = useUserAllPetPhotos();
  const { activePets, inactivePets } = getPetsByStatus(petsData);
  const hasActiveInsurance = activePets.some(pet => pet.policies?.length);

  const imageWrapperClassName = classNames({
    [styles.imageWrapper]: activePets.length < 3,
  });

  return (
    <>
      <MemberCenterBanners />

      <div className={styles.root}>
        <div className={styles.title}>
          <h5>All Pets</h5>
          <h2>All Pets</h2>
        </div>

        {isFetching ? (
          <Spinner classes={{ root: styles.spinner }} />
        ) : (
          <>
            <div className={styles.flexContainer}>
              {activePets.map(pet => {
                const petPhoto =
                  userPetPhotos?.find(item => item.pet_id === pet.id)?.location ||
                  PLACEHOLDER[pet.species];

                return (
                  <PetCard
                    key={pet.id}
                    pet={pet}
                    toPet={`/pets/${pet.id}/plan`}
                    toClaim={`/pets/${pet.id}/file-claim`}
                    petPhoto={petPhoto}
                    classes={{ imageWrapper: imageWrapperClassName }}
                  />
                );
              })}

              {isAllPets || !activePets.length
                ? inactivePets.map(pet => {
                    const petPhoto =
                      userPetPhotos?.find(item => item.pet_id === pet.id)
                        ?.location || PLACEHOLDER[pet.species];

                    return (
                      <PetCard
                        key={pet.id}
                        pet={pet}
                        toPet={`/pets/${pet.id}/plan`}
                        toClaim={`/pets/${pet.id}/file-claim`}
                        petPhoto={petPhoto}
                        classes={{ imageWrapper: imageWrapperClassName }}
                      />
                    );
                  })
                : null}
            </div>

            {inactivePets.length && activePets.length ? (
              <button
                className={classNames(styles.toggle, ButtonStyles.tertiary)}
                onClick={toggleAllPets}
              >
                {isAllPets ? 'Show Active Pets Only' : 'Show All Pets'}
                <ChevronRight
                  width={8}
                  height={14}
                  className={isAllPets ? styles.open : styles.closed}
                />
              </button>
            ) : null}
          </>
        )}

        <div className={styles.callout}>
          <img src={callout} alt='' />

          <LegalBody>
            {punks1012SawFe
              ? 'Unlock peace of mind with pet insurance for your whole pack'
              : 'Get a 10% multi-pet discount for each additional pet you enroll'}
          </LegalBody>

          <Link
            href={QF_URL}
            className={ButtonStyles.secondary}
            onClick={() => removeCookie(QUOTE_ID_COOKIE_NAME)}
          >
            {!punks1012SawFe
              ? 'Add a Pet'
              : hasActiveInsurance
              ? 'Add a Pet - Get 10% Off'
              : 'Learn About Insurance'}
          </Link>

          {punks1012SawFe ? (
            <Link
              href={WELLNESS_URL}
              className={classNames(styles.wellnessLink, ButtonStyles.tertiary)}
            >
              Explore Our New Wellness
            </Link>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default MemberPets;
