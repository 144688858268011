import { API, Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import Store from 'store';

import { configureAmplifyCustomAuth } from '@pumpkincare/config';
import { COMPLETE_QUOTE_ID_LS_KEY, setIsLoggedIn } from '@pumpkincare/shared';
import { GRAND_TOTAL_LOCAL_STORAGE_KEY } from '@pumpkincare/shared';

import { Paths } from '../../app-shell';
import {
  getSelf,
  setUserIsRegistrationLoading,
  setUserRegistrationError,
} from '../index';

export default function checkUserToken(
  token,
  userId,
  email,
  password,
  tokenType = 'password_signup'
) {
  return (dispatch, getState, { userService }) => {
    dispatch(setUserIsRegistrationLoading(true));

    return userService
      .checkUserToken({ token, tokenType })
      .then(() => {
        return dispatch(registerUser(userId, email, password, token, tokenType));
      })
      .catch(error => {
        dispatch(setUserIsRegistrationLoading(false));
        dispatch(
          setUserRegistrationError({
            isTokenError: true,
            errorMessage: `Sorry, your reset password link has expired. Select the button below to request a new password link.`,
          })
        );
        throw error;
      });
  };
}

function registerUser(
  userId,
  email,
  password,
  token,
  tokenType = 'password_signup'
) {
  return dispatch => {
    configureAmplifyCustomAuth();

    const signinFunc = user => {
      if (user.challengeName === 'CUSTOM_CHALLENGE') {
        const tokenName =
          tokenType === 'password_signup' ? 'token' : 'vet_survey_token';

        const answer = `${tokenName}::${token}`;

        return Auth.sendCustomChallengeAnswer(user, answer)
          .then(user => {
            const cognitoToken = user.signInUserSession.idToken.jwtToken;

            const apiName = 'policyApi';
            const path = `/users/${userId}`;

            const putBody = {
              password,
            };

            const init = {
              headers: {
                Authorization: `Bearer ${cognitoToken}`,
              },
              body: putBody,
              response: true,
            };
            return API.put(apiName, path, init);
          })
          .then(() => {
            return dispatch(getSelf());
          })
          .then(() => {
            Store.remove(GRAND_TOTAL_LOCAL_STORAGE_KEY);
            Store.remove(COMPLETE_QUOTE_ID_LS_KEY);

            setIsLoggedIn(true);
            dispatch(push(Paths.Landing));
          });
      }
    };

    return Auth.signIn(email)
      .then(signinFunc)
      .catch(error => {
        dispatch(setUserIsRegistrationLoading(false));
        dispatch(
          setUserRegistrationError({
            errorMessage: error.message,
          })
        );
      });
  };
}
